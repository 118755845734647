var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-3"},[(_vm.loadingView)?[_c('is-loading',{attrs:{"loading":_vm.loadingView,"has-data":!_vm.loadingView,"loading-label":_vm.translate('loading'),"no-data-label":_vm.translate('data_not_found')}})]:[_c('b-alert',{attrs:{"show":""}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.translate('section_explanation_' + _vm.getWorld()))}})]),_c('b-alert',{attrs:{"variant":"warning","show":""}},[(_vm.meta.canUpdate)?[_c('div',{domProps:{"innerHTML":_vm._s(_vm.translate('section_explanation_relative_date', _vm.dateInterval))}})]:_vm._e(),(!_vm.meta.canUpdate && _vm.meta.nextUpdateDate !== null)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.translate('section_explanation_specific_date', {
					date: _vm.$moment(_vm.meta.nextUpdateDate.date).format(_vm.dateFormat)
				}))}}):_vm._e()],2),_vm._l((_vm.cells),function(cell,index){return _c('div',{key:index},[_c('b-row',{staticStyle:{"max-width":"1000px"}},[_c('b-col',{staticClass:"cell",attrs:{"cols":"12","lg":"8"}},[_c('div',[_c('h2',{staticClass:"group-name"},[_vm._v(" "+_vm._s(_vm.translate(cell.groupName + '_group_name'))+" ")]),_vm._l((cell.images),function(image){return _c('img',{key:image,staticClass:"group-image",attrs:{"src":image,"alt":"Product image"}})})],2)]),_c('b-col',{staticClass:"cell",attrs:{"cols":"12","lg":"4"}},[_c('div',[_c('label',{attrs:{"for":"price-input"}},[_c('b',[_vm._v(_vm._s(_vm.translate('price'))+":")])]),_c('b-input-group',{attrs:{"prepend":"$","append":"USD"}},[_c('b-input',{staticClass:"price-input text-right",class:{
									'is-invalid': _vm.isInvalid(cell.currentPrice, cell.minPrice, cell.maxPrice)
								},attrs:{"type":"number","disabled":_vm.loading || !_vm.canUpdate,"min":cell.minPrice,"max":cell.maxPrice,"step":0.01},model:{value:(cell.currentPrice),callback:function ($$v) {_vm.$set(cell, "currentPrice", $$v)},expression:"cell.currentPrice"}})],1),_c('small',{class:{
								'form-text': true,
								'text-danger': _vm.isInvalid(cell.currentPrice, cell.minPrice, cell.maxPrice),
								'text-muted': !_vm.isInvalid(cell.currentPrice, cell.minPrice, cell.maxPrice),
							}},[_vm._v(" "+_vm._s(_vm.translate('price_between', { min: cell.formattedMinPrice, max: cell.formattedMaxPrice, }))+" ")])],1)])],1),_c('hr',{staticClass:"divider"})],1)}),(_vm.meta.canUpdate)?_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loading || _vm.isAnyPriceInvalid || !_vm.canUpdate},on:{"click":_vm.submit}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.translate('submit'))+" ")])],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }