<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="p-3">
		<template v-if="loadingView">
			<is-loading
				:loading="loadingView"
				:has-data="!loadingView"
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')" />
		</template>
		<template v-else>
			<b-alert show>
				<div v-html="translate('section_explanation_' + getWorld())" />
			</b-alert>
			<b-alert
				variant="warning"
				show>
				<template v-if="meta.canUpdate">
					<div v-html="translate('section_explanation_relative_date', dateInterval)" />
				</template>
				<div
					v-if="!meta.canUpdate && meta.nextUpdateDate !== null"
					v-html="translate('section_explanation_specific_date', {
						date: $moment(meta.nextUpdateDate.date).format(dateFormat)
					})" />
			</b-alert>
			<div
				v-for="(cell, index) in cells"
				:key="index">
				<b-row style="max-width: 1000px">
					<b-col
						cols="12"
						lg="8"
						class="cell">
						<div>
							<h2 class="group-name">
								{{ translate(cell.groupName + '_group_name') }}
							</h2>
							<img
								v-for="image in cell.images"
								:key="image"
								:src="image"
								alt="Product image"
								class="group-image">
						</div>
					</b-col>
					<b-col
						cols="12"
						lg="4"
						class="cell">
						<div>
							<label for="price-input"><b>{{ translate('price') }}:</b></label>
							<b-input-group
								prepend="$"
								append="USD">
								<b-input
									v-model="cell.currentPrice"
									type="number"
									:disabled="loading || !canUpdate"
									:min="cell.minPrice"
									:max="cell.maxPrice"
									:step="0.01"
									:class="{
										'is-invalid': isInvalid(cell.currentPrice, cell.minPrice, cell.maxPrice)
									}"
									class="price-input text-right" />
							</b-input-group>
							<small
								:class="{
									'form-text': true,
									'text-danger': isInvalid(cell.currentPrice, cell.minPrice, cell.maxPrice),
									'text-muted': !isInvalid(cell.currentPrice, cell.minPrice, cell.maxPrice),
								}">
								{{
									translate('price_between', {
										min: cell.formattedMinPrice,
										max: cell.formattedMaxPrice,
									})
								}}
							</small>
						</div>
					</b-col>
				</b-row>
				<hr class="divider">
			</div>
			<div
				v-if="meta.canUpdate"
				class="d-flex justify-content-end">
				<b-button
					variant="primary"
					:disabled="loading || isAnyPriceInvalid || !canUpdate"
					@click="submit">
					<i
						v-if="loading"
						class="fas fa-spinner fa-spin" />
					{{ translate('submit') }}
				</b-button>
			</div>
		</template>
	</div>
</template>

<script>
import { CustomPricing } from '@/translations';
import CustomPricingApi from '@/util/CustomPricing';
import { MMMDYHMA_FORMAT } from '@/settings/Dates';
import { UNPROCESSABLE } from '@/settings/Errors';

export default {
	name: 'CustomPrices',
	messages: [CustomPricing],
	data() {
		return {
			customPricing: new CustomPricingApi(),
			customPricingUpdate: new CustomPricingApi(),
			cells: [],
			alert: new this.$Alert(),
			dateFormat: MMMDYHMA_FORMAT,
		};
	},
	computed: {
		isAnyPriceInvalid() {
			return this.cells.some((cell) => this.isInvalid(cell.currentPrice, cell.minPrice, cell.maxPrice));
		},
		canUpdate() {
			return this.$can('custom_prices', 'update') && this.meta.canUpdate;
		},
		loading() {
			return !!this.customPricingUpdate.data.loading;
		},
		loadingView() {
			return !!this.customPricing.data.loading;
		},
		meta() {
			const meta = {
				canUpdate: false,
				nextUpdateDate: null,
				intervalValue: null,
				intervalUnit: null,
			};
			try {
				const data = this.customPricing.data.response.data.meta;
				meta.canUpdate = data.can_update;
				meta.nextUpdateDate = data.next_update_date;
				meta.intervalValue = data.date_units.value;
				meta.intervalUnit = data.date_units.unit;
			} catch (error) {
				// ignore
			}
			return meta;
		},
		dateInterval() {
			return {
				value: this.meta.intervalValue,
				unit: this.translate(this.meta.intervalUnit + (this.meta.intervalValue > 1 ? '_plural' : '')),
			};
		},
		formattedNextUpdateDate() {
			return {
				date: this.$moment(this.meta.nextUpdateDate.date).format(this.dateFormat),
			};
		},
		errors() {
			return this.customPricingUpdate.data.errors;
		},
	},
	mounted() {
		this.getInfo();
	},
	methods: {
		getWorld() {
			return this.$route.name === 'CustomPricesDeveloped' ? 'developed' : 'emerging';
		},
		getInfo() {
			const world = this.getWorld();
			this.customPricing.getCustomPrices({ world }).then((items) => {
				this.cells = [];
				items.forEach((item) => {
					this.cells.push({
						images: item.attributes.products,
						groupName: item.id,
						maxPrice: item.attributes.max_price.amount,
						formattedMaxPrice: item.attributes.max_price.formatted_amount,
						minPrice: item.attributes.min_price.amount,
						formattedMinPrice: item.attributes.min_price.formatted_amount,
						currentPrice: item.attributes.current_price.amount,
					});
				});
			});
		},
		submit() {
			const trans = {
				title: this.translate('submit_custom_prices_title'),
				label: this.translate('password'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
				message: this.translate('submit_custom_prices_message', this.dateInterval),
			};
			this.alert.confirmationWithPassword(trans.title, trans.label, options).then((password) => {
				this.updatePrices(password.value);
			}).catch(() => {});
		},
		isInvalid(price, min, max) {
			return price < min || price > max;
		},
		updatePrices(password) {
			const payload = {
				world: this.getWorld(),
				password,
				categories: this.cells.map((cell) => ({ type: cell.groupName, price: cell.currentPrice })),
			};
			this.customPricingUpdate.setCustomPrices(payload).then(() => {
				this.alert.toast('success', this.translate('success'));
				this.getInfo();
			}).catch(() => {
				this.handleErrors();
			});
		},
		handleErrors() {
			let errorName = 'default_error_message';
			if (UNPROCESSABLE.includes(this.errors.status) && typeof this.errors.errors.password !== 'undefined') {
				errorName = 'incorrect_password';
			}
			this.alert.toast('error', this.translate(errorName));
		},
	},
};
</script>

<style scoped>
.cell {
	padding-top: 10px;
	padding-bottom: 10px;
}

.group-image {
	width: 45px;
	height: auto;
}

.group-name {
	font-size: 1.2em;
	font-weight: bold;
}

.divider {
	margin: 20px 0;
}
</style>
