import {
	GET_CUSTOM_PRICES,
	SET_CUSTOM_PRICES,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class CustomPricing {
	constructor() {
		const axios = new Req();
		this.data = axios;
		this.errors = axios.errors;
		this.loading = axios.loading;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	getCustomPrices(options) {
		const { method, endpoint } = GET_CUSTOM_PRICES;
		return this.data[method](endpoint, options, true).then((response) => response).catch((error) => {
			this.errors.record(error); return Promise.reject(error);
		});
	}

	setCustomPrices(options) {
		const { method, endpoint } = SET_CUSTOM_PRICES;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default CustomPricing;
